import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from "redux-thunk"

import { authReducer } from "../reducers/authReducer"
import { dataInitialReducer } from '../reducers/dataInitialReducer';
import { mantenimientoReducer } from '../reducers/mantenimientoReducer';
import { changeStateReducer } from '../reducers/templateReducer';
import { uiReducer } from "../reducers/uiReducer"

//const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  api: dataInitialReducer,
  mantenimiento: mantenimientoReducer,
  changeState: changeStateReducer
});

const store = createStore(
  reducers,
  applyMiddleware(thunk)
)
export default store