import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import AuthRouter from "./AuthRouter";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import DashboardRoutes from "./DashboardRoutes";
import {
  validateKeysLocalStorage,
  verifyJWTApi,
  validateInitialKeysLocalStorage,
} from "../services/login.services";

export const AppRouter = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  validateInitialKeysLocalStorage();

  const verifySession = async () => {
    try {
      await verifyJWTApi();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
    }
  };

  const loadApplication = () => {
    return (
      <div className="container">
        <h4>Espere un momento....</h4>
      </div>
    );
  };

  useEffect(() => {
    verifySession();
  }, []);

  if (validateKeysLocalStorage() && !isLoggedIn) {
    return loadApplication();
  }

  return (
    <Router>
      <Switch>
        <PublicRoute
          path={`/login`}
          component={AuthRouter}
          isAutenticated={isLoggedIn}
        />

        <PrivateRoute
          exact
          path={`/`}
          component={DashboardRoutes}
          isAutenticated={isLoggedIn}
        />

        <Redirect to={`/login`} />
      </Switch>
    </Router>
  );
};
