export const types = {
    auth_Login: '[Auth] Login',
    auth_Logout: '[Auth] Logout',
    auth_load_data: '[Auth] Load Data',
    auth_load_dataCombos: '[Auth] Load Data Combos',
    auth_Finish: '[Auth] Finish',

    API_REPORTES: "API",

    ui_SetError: '[UI] Set Error',
    ui_RemoveError: '[UI] Set Remove Error',
    
    ui_StartLoading: '[UI] Start Loading',
    ui_FinishLoading: '[UI] Finish Loading',

    mantenimiento_Usuarios_ADD: '[MantenimientoUsuarios] Add',
    mantenimiento_Usuarios_UPDATE: '[MantenimientoUsuarios] UPDATE',
    mantenimiento_Usuarios_DELETE: '[MantenimientoUsuarios] DELETE',
   
    mantenimiento_Evaluadores_ADD: '[MantenimientoEvaluadores] Add',
    mantenimiento_Evaluadores_UPDATE: '[MantenimientoEvaluadores] UPDATE',
    mantenimiento_Evaluadores_DELETE: '[MantenimientoEvaluadores] DELETE',

    mantenimiento_InsertStatus_On: '[Mantenimiento] InsertStatusOn',
    mantenimiento_InsertStatus_Off: '[Mantenimiento] InsertStatusOff',

    mantenimiento_UpdateStatus_On: '[Mantenimiento] UpdateStatusOn',
    mantenimiento_UpdateStatus_Off: '[Mantenimiento] UpdateStatusOff',

    mapasLotesCargado: '[Mapa] Lotes Cargado',
    mapasGpsCargado: '[Mapa] GPS Cargado',

    mapasVistaLotesLabels: '[Mapa] Lotes Vista Lotes',
    mapasVistaGpsLabels: '[Mapa] GPS Vista Labels'
};